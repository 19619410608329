import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import PortableText from 'react-portable-text';

import { Grid, Typography, useMediaQuery } from '@material-ui/core';

export const OverviewFeature = ({
	overviewHeader,
	overviewBody,
	overviewImage,
}) => {
	const med = useMediaQuery('(max-width:600px)');
	return (
		<Grid container direction='row' justifyContent='space-evenly' spacing={1}>
			<Grid
				item
				container
				direction='row'
				alignItems='center'
				justifyContent='center'
				style={{ marginBottom: med && '2rem' }}
				xs={12}
				md={5}>
				<GatsbyImage image={overviewImage?.asset?.gatsbyImageData} />
			</Grid>
			<Grid
				item
				container
				xs={12}
				md={6}
				spacing={2}
				direction='column'
				justifyContent='center'
				alignItems='flex-start'>
				<Grid item>
					<Typography
						variant='h2'
						color='primary'
						style={{
							lineHeight: 1.4,
						}}>
						{overviewHeader}
					</Typography>
				</Grid>
				<Grid item>
					{overviewBody?.map((content, index) => (
						<PortableText
							key={index}
							content={content}
							serializers={{
								normal: ({ children }) => (
									<Typography
										variant='body1'
										style={{
											color: '#4B5B69',
											lineHeight: 1.7,
											fontFamily: 'Roboto',
										}}>
										{children}
									</Typography>
								),
							}}
						/>
					))}
				</Grid>
			</Grid>
		</Grid>
	);
};
