import React, { useContext } from 'react';
import { BgImage } from 'gbimage-bridge';
import PortableText from 'react-portable-text';

import {
	Button,
	Typography,
	Grid,
	makeStyles,
	useMediaQuery,
} from '@material-ui/core';
import { Link } from 'gatsby';

import FormModalContext from '../../context/FormModalContext';

const useStyles = makeStyles((theme) => ({
	wrapper: {
		padding: '6rem 0 4rem 0',
		[theme.breakpoints.down('md')]: {
			padding: '4rem 0 0 0',
		},
	},
	bgImg: {
		padding: '2rem 0',

		[theme.breakpoints.down('sm')]: {
			padding: '2rem 0',
			backgroundPosition: 'center center',
			paddingTop: '17rem',
		},
		[theme.breakpoints.down('xs')]: { paddingTop: '14rem' },
	},
	//
	button: {
		background: '#0F95A4',
		color: 'white',
		padding: '16px 32px',
		opacity: 1,
		fontSize: '1.1rem',
		fontWeight: 500,
		zIndex: 1000,
		transition: 'all 0.35s ease !important',
		'&:hover': {
			background: '#0F95A4',
			opacity: '0.85 !important',
		},
		[theme.breakpoints.down('md')]: {
			margin: '1rem 0 .5rem',
			padding: '12px 28px',
		},
		[theme.breakpoints.down('sm')]: {
			margin: '1rem 0',
		},
		[theme.breakpoints.down('xs')]: {
			margin: '2rem 0 1rem',
		},
	},
	// content: {
	// 	color: '#FFF',
	// 	[theme.breakpoints.down('md')]: {
	// 		textAlign: 'flex-start',
	// 		padding: '0 25px',
	// 	},
	// },
	calloutHeader: {
		fontSize: '2rem',
		fontWeight: 700,
		lineHeight: 1.2,
		color: theme.workwaveBlue,
		// marginBottom: '1rem',
		[theme.breakpoints.down('md')]: {
			textAlign: 'flex-start',
			padding: '15px 0 0 15px',
		},
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
		},
	},
	calloutSubHeader: {
		color: '#FFF',
		[theme.breakpoints.down('md')]: {
			textAlign: 'left',
			padding: '0 15px',
		},
	},
	contentContainer: {
		justifyContent: 'flex-end',
		alignItems: 'flex-end',
		[theme.breakpoints.down('md')]: {
			justifyContent: 'flex-end',
			alignItems: 'center',
		},
		// [theme.breakpoints.down('sm')]: {
		// 	justifyContent: 'flex-start',
		// 	alignItems: 'center',
		// 	marginLeft: '40%',
		// },
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center',
			alignItems: 'center',
			margin: 0,
		},
	},
	buttonContainer: {
		// marginTop: '-1rem',
		// [theme.breakpoints.down('sm')]: {
		// 	justifyContent: 'flex-start',
		// 	alignItems: 'center',
		// 	marginLeft: '40%',
		// 	marginTop: 0,
		// },
		[theme.breakpoints.down('sm')]: {
			justifyContent: 'center',
			alignItems: 'center',
			margin: 0,
		},
	},
}));

export const IndustryCtaCard = ({
	background,
	header,
	subheader,
	ctaText,
	ctaLink,
	internalLink,
	mobileBackground,
}) => {
	const classes = useStyles();

	const { handleModalClick } = useContext(FormModalContext);

	const md = useMediaQuery('(max-width: 960px)');
	return (
		<div className={classes.wrapper}>
			<BgImage
				style={{
					borderRadius: '20px',
					overflow: 'hidden',
					backgroundSize: 'cover',
					boxShadow: '-8px 8px 24px rgba(0, 0, 0, 0.15)',
				}}
				className={classes.bgImg}
				image={
					md && mobileBackground
						? mobileBackground.asset?.gatsbyImageData
						: background.asset?.gatsbyImageData
				}>
				<Grid
					container
					direction='row'
					justifyContent='space-evenly'
					alignItems='center'
					style={{ height: '100%' }}>
					<Grid
						item
						container
						direction='row'
						xs={12}
						md={8}
						className={classes.contentContainer}
						style={{ height: '100%' }}>
						<PortableText
							content={header}
							serializers={{
								h3: ({ children }) => (
									<Typography variant='h3' className={classes.calloutHeader}>
										{children}
									</Typography>
								),
							}}
						/>
						{/* <Typography variant='h5' className={classes.calloutHeader}>
							{header}
						</Typography> */}
						{/* <Typography variant='body1' className={classes.calloutSubHeader}>
							{subheader}
						</Typography> */}
					</Grid>
					<Grid
						item
						container
						alignItems='center'
						justifyContent='center'
						className={classes.buttonContainer}
						xs={12}
						md={4}
						style={{ height: '100%' }}>
						{ctaLink ? (
							internalLink ? (
								<Link to={`/${ctaLink}`} style={{ textDecoration: 'none' }}>
									<Button
										variant='contained'
										size='large'
										className={classes.button}>
										{ctaText}
									</Button>
								</Link>
							) : (
								<a
									href={ctaLink}
									target='_blank'
									style={{ textDecoration: 'none' }}>
									<Button
										variant='contained'
										size='large'
										className={classes.button}>
										{ctaText}
									</Button>
								</a>
							)
						) : (
							<Button
								variant='contained'
								size='large'
								className={classes.button}
								onClick={handleModalClick}>
								{ctaText}
							</Button>
						)}
					</Grid>
				</Grid>
			</BgImage>
		</div>
	);
};
