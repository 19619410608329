import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	relatedSection: {
		padding: '0',
		zIndex: '2',
		position: 'relative',
		[theme.breakpoints.down('sm')]: {
			padding: '2rem 0',
		},
	},
	header: {
		color: theme.workwaveBlue,
		textAlign: 'center',
	},
	subHeader: {
		color: '#5A7184',
		textAlign: 'center',
	},
	link: {
		fontSize: 16,
		fontWeight: 'bold',
		color: theme.workwaveBlue,
		textAlign: 'center',
		width: '80%',
	},
	item: {
		padding: '.2rem 0',
	},
	linkCard: {
		boxShadow: '0 10px 40px 0px rgba(0,0,0,0.10)',
		borderRadius: '8px',
		cursor: 'pointer',
		background: theme.white,
		width: '90%',
		transition: 'transform 0.25s, box-shadow 0.75s',
		width: '98%',
		'&:hover': {
			transform: 'scale(1.02)',
			transition: 'transform 0.25s, box-shadow 0.75s',
			cursor: 'pointer',
			boxShadow:
				'0 13px 27px -5px rgba(50, 50, 93, 0.2), 0 8px 16px -8px rgba(0, 0, 0, 0.3)',
		},
	},
}));

export const Related = ({ related, header, accentColor }) => {
	const classes = useStyles();
	return (
		<div style={{}} className={classes.relatedSection}>
			<Grid
				container
				direction='column'
				justifyContent='center'
				alignContent='center'
				spacing={2}
				style={{ marginBottom: '2rem' }}>
				<Grid item>
					<Typography variant='h2' className={classes.header}>
						{header}
					</Typography>
				</Grid>
			</Grid>
			<Grid container direction='row' spacing={3}>
				{related.map((related, index) => (
					<Grid item container xs={12} sm={6} lg={4} key={index}>
						<div className={classes.linkCard}>
							<Link
								to={`/industries/${related.slug.current}`}
								style={{ textDecoration: 'none' }}>
								<Grid container direction='row' alignItems='center'>
									<FontAwesomeIcon
										icon={['fad', related.faIcon]}
										style={{
											height: '40px',
											width: '40px',
											border: `1px solid #E5EAF4`,
											background: '#E5EAF4',
											borderRadius: '8px 0 0 8px',
											padding: '6px',
											color: related.accentColor[0]?.hexValue ?? '#002D5C',
										}}
									/>
									<Typography className={classes.link}>
										{related.title}
									</Typography>
								</Grid>
							</Link>
						</div>
					</Grid>
				))}
			</Grid>
			<Grid
				container
				direction='row'
				justifyContent='center'
				alignItems='center'>
				<Link to='/industries'>
					<Button
						variant='contained'
						size='medium'
						style={{
							marginTop: '2rem',
							background: accentColor[0].hexValue ?? '#002D5C',
							color: 'white',
						}}>
						view all industries
					</Button>
				</Link>
			</Grid>
		</div>
	);
};
