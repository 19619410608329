import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { makeStyles } from '@material-ui/core/styles';
import { Dialog, Backdrop, Grid } from '@material-ui/core';

import { SoftwareFeatures } from '.';
import { AbridgedSoftwareFeatures } from './AbridgedSoftwareFeatures';
const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		background: 'transparent',
	},
	paper: {
		borderRadius: '20px',
		maxWidth: '1280px',
		overflowX: 'hidden',
		padding: '0',
		margin: '0 16px',
	},
	closeIcon: {
		color: theme.white,
		backgroundColor: 'transparent',
		fontSize: 26,
		marginBottom: '0',
		position: 'absolute',
		top: '0',
		right: '10px',
		zIndex: '99',
		transition: 'all .3s ease-out',
		opacity: '0.8',
		'&:hover': {
			cursor: 'pointer',
			opacity: '1',
			transform: 'rotate(180deg) ',
		},
	},
}));

export const FeatureModal = ({
	open,
	setFeatureModalOpen,
	header,
	subheader,
	softwareFeatures,
	accentColor,
}) => {
	const classes = useStyles();

	return (
		open && (
			<div>
				<Dialog
					aria-labelledby='spring-modal-title'
					aria-describedby='spring-modal-description'
					className={classes.modal}
					classes={{
						paper: classes.paper,
					}}
					maxWidth='lg'
					open={open}
					onClose={(e) => setFeatureModalOpen(false)}
					BackdropComponent={Backdrop}>
					<FontAwesomeIcon
						icon={['fas', 'xmark']}
						size='1x'
						onClick={(e) => setFeatureModalOpen(false)}
						className={classes.closeIcon}
					/>

					<Grid>
						{[
							'5921cb3a-f3d4-47c1-9f67-26b8d10d63f8',
							'6970c719-613c-475b-b27a-a5acea68444f',
						].includes(softwareFeatures._id) ? (
							<AbridgedSoftwareFeatures
								header={header}
								subheader={subheader}
								softwareFeatures={softwareFeatures}
								modal={true}
								accentColor={accentColor}
								teamLite={
									softwareFeatures._id ===
									'6970c719-613c-475b-b27a-a5acea68444f'
								}
							/>
						) : (
							<SoftwareFeatures
								header={header}
								subheader={subheader}
								softwareFeatures={softwareFeatures}
								modal={true}
							/>
						)}
					</Grid>
				</Dialog>
			</div>
		)
	);
};
